<template>
	<div class="button">
		<button
			class="inline-flex btn cursor-pointer items-center leading-7 border-transparent shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
			:class="{
				'text-white bg-blue-500 hover:bg-blue-600': color === 'blue',
				'px-4 py-2 text-sm': size === 'smoll',
				'px-12 py-2.5 text-md': size === 'medium',
				'px-12 py-2.5 text-md w-full justify-center': size === 'full',
				'text-textGray border-textGray pointer-events-none': disabled,
				'text-blue-500 bg-white border-blue-500 hover:bg-blue-500 hover:text-white': color === 'white',
				'border-2': border === 'thick',
				border: border === ''
			}"
			:disabled="disabled"
			v-bind="$attrs"
			@click="$emit('click')"
		>
			<svg v-if="load" class="spinner -ml-1 mr-2 h-5 w-5" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
				<circle class="path" fill="none" :stroke="spinnerColor" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
			</svg>
			<Icons class="-ml-1 mr-2" :class="{ white: color === 'white' }" v-else-if="icon" :icon-name="icon" />
			<p>{{ text }}</p>
		</button>
	</div>
</template>

<script>
import Icons from '@/components/Icons.vue';

export default {
	name: 'Button',
	components: { Icons },
	props: {
		text: String,
		icon: String,
		size: {
			type: String,
			default: 'smoll'
		},
		load: {
			type: Boolean,
			default: false
		},
		color: {
			type: String,
			default: 'blue'
		},
		spinnerColor: {
			type: String,
			default: 'white'
		},
		border: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		}
	}
};
</script>

<style>
.btn {
	transition: 0.3s;
}

.btn:hover .icon.white path {
	fill: #fff;
}

.spinner {
	animation: rotator 1.4s linear infinite;
	position: relative;
	top: -1px;
}

@keyframes rotator {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(270deg);
	}
}
</style>
